import React, { useState } from 'react'

import CellComponent from './CellComponent'

const FieldComponent = ({ field }) => {
  // eslint-disable-next-line
  const [state, setState] = useState(false)

  function reloadField() {
    setState(true)
  }

  return (
    <div className="field">
      {field.cells.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {value.map((value, index) => {
              return (
                <CellComponent
                  cell={value}
                  key={index}
                  reloadField={reloadField}
                />
              )
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default FieldComponent
