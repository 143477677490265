import { Cell, Sign } from './Cell'

export class Field {
  dimension = 3
  cells = []
  win = null //  const [win, setWin] = useState(false)
  currentSign = Sign.First //  const [player, setPlayer] = useState(Sign.First)

  constructor() {
    this.initField()
  }

  initField() {
    let tmp = []
    for (let i = 0; i < this.dimension; i++) {
      let a = []
      for (let j = 0; j < this.dimension; j++) {
        a.push(new Cell(j, i, this))
      }
      tmp.push(a)
    }

    this.cells = tmp
  }

  setWin(array) {
    this.win = array
    this.cells.map((line) => {
      line.map((cell) => {
        let tmpArr = this.win.filter(
          (wn) => wn[0] === cell.y && wn[1] === cell.x
        )
        if (tmpArr.length > 0) cell.green = true
        return true
      })
      return true
    })
  }

  testWin() {
    for (let i = 0; i < this?.dimension; i++)
      if (
        Math.abs(
          this.cells[i][0].sign + this.cells[i][1].sign + this.cells[i][2].sign
        ) > 2
      ) {
        this.setWin([
          [i, 0],
          [i, 1],
          [i, 2],
        ])
      }
    for (let i = 0; i < this.dimension; i++)
      if (
        Math.abs(
          this.cells[0][i].sign + this.cells[1][i].sign + this.cells[2][i].sign
        ) > 2
      )
        this.setWin([
          [0, i],
          [1, i],
          [2, i],
        ])

    let s = 0
    for (let i = 0, j = 0; i < this.dimension && j < this.dimension; i++, j++)
      s += this.cells[i][j].sign
    if (Math.abs(s) > 2)
      this.setWin([
        [0, 0],
        [1, 1],
        [2, 2],
      ])
    s = 0
    for (
      let i = 0, j = this.dimension - 1;
      i < this.dimension && j >= 0;
      i++, j--
    )
      s += this.cells[i][j].sign
    if (Math.abs(s) > 2)
      this.setWin([
        [2, 0],
        [1, 1],
        [0, 2],
      ])
  }

  //switch player
  toggleSign() {
    this.testWin()
    this.currentSign =
      this.currentSign === Sign.First ? Sign.Second : Sign.First
  }
}
