import svgX from '../assets/x.svg'
import svgO from '../assets/o.svg'
import { Sign } from '../model/Cell'
import { useState } from 'react'

const CellComponent = ({ cell, reloadField }) => {
  const [state, setState] = useState(cell.sign)

  function toggleCellComponent() {
    setState(cell.field.currentSign)
    cell.toggleCell()
    if (cell.field?.win) reloadField()
  }

  return (
    <div
      className={cell.green ? 'cell win' : 'cell'}
      onClick={() =>
        state === Sign.No && !cell.field?.win ? toggleCellComponent() : ''
      }
    >
      {state === Sign.No ? (
        ''
      ) : state === Sign.First ? (
        <img src={svgX} alt="X" />
      ) : (
        <img src={svgO} alt="O" />
      )}
    </div>
  )
}
export default CellComponent
