export const Sign = {
  No: 0,
  First: -1,
  Second: 1,
}

export class Cell {
  x
  y
  sign = Sign.No
  field
  green = false

  constructor(x, y, field) {
    this.x = x
    this.y = y
    this.field = field
  }

  toggleCell() {
    this.sign = this.field.currentSign
    this.field.toggleSign()
  }
}
