import * as React from 'react'
import FieldComponent from './components/FieldComponent'
import { Field } from './model/Field'

function App() {
  const [field, setField] = React.useState(new Field())

  // eslint-disable-next-line
  function restart() {
    setField(new Field())
  }

  return (
    <div className="app">
      <FieldComponent field={field} />
    </div>
  )
}

export default App
